/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */
import '../styles/NBFCPage.css';

import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import onBoarding from "../assets/images/onBording2.png"
import circle_Img from "../assets/productImg/AIML Screening Techniques (1) (1).png"
import HighInterestIcon from '@mui/icons-material/TrendingUp';
import rc_IMG from "../assets/productImg/loan.png"
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MarketAccessIcon from '@mui/icons-material/Public';
import AcquisitionIcon from '@mui/icons-material/PersonAdd';
import TechInfrastructureIcon from '@mui/icons-material/Build';
import RecoverySupportIcon from '@mui/icons-material/Support';
import NpaMitigationIcon from '@mui/icons-material/Security';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Grid, Typography, Paper, IconButton, Collapse, Fade } from '@mui/material';
import { Stepper, Step, StepLabel, StepContent, Button } from '@mui/material';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BuildIcon from '@mui/icons-material/Build';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import SupportIcon from '@mui/icons-material/Support';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { useMediaQuery } from '@mui/material';
const NBFCPage = () => {
  useEffect(() => {
    AOS.init(
      {
        duration: "1000",
        delay: "300"
      }
    );
  }, [])

  const [hoveredStep, setHoveredStep] = useState(null);
  // Using MediaQuery to make responsive changes based on screen size
  const isSmallScreen = useMediaQuery('(max-width:600px)'); // Mobile screens
  const isMediumScreen = useMediaQuery('(max-width:960px)'); // Tablet screens

  // Responsive arrow sizes
  const arrowSize = isSmallScreen ? '2rem' : isMediumScreen ? '3rem' : '4rem';
  const iconSize = isSmallScreen ? '60px' : '80px';

  const steps = [
    {
      icon: <ContactMailIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Initial Contact",
      description: "Our team will reach out to discuss your goals, business requirements, and financial objectives.",
      image: "https://via.placeholder.com/600x300?text=Initial+Contact", // replace with actual image URL
    },
    {
      icon: <PersonSearchIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Customer Identification",
      description: "FinLe identifies prospective EV rickshaw buyers and pre-screens them for creditworthiness.",
      image: "https://via.placeholder.com/600x300?text=Customer+Identification", // replace with actual image URL
    },
    {
      icon: <SyncAltIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Technology Setup",
      description: "We provide all technological infrastructure required for digital onboarding, EMI processing, and more.",
      image: "https://via.placeholder.com/600x300?text=Technology+Setup", // replace with actual image URL
    },
    {
      icon: <DescriptionIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Documentation & KYC",
      description: "Our platform facilitates digital KYC and documentation, speeding up the loan process.",
      image: "https://via.placeholder.com/600x300?text=Documentation+%26+KYC", // replace with actual image URL
    },
    {
      icon: <LocalAtmIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Loan Disbursement",
      description: "Once everything is in place, the loan is disbursed, and the e-rickshaw is delivered to the customer.",
      image: "https://via.placeholder.com/600x300?text=Loan+Disbursement", // replace with actual image URL
    },
    {
      icon: <ForwardToInboxIcon sx={{ color: '#F37021', fontSize: 40 }} />,
      title: "Ongoing Support",
      description: "FinLe provides continuous support through EMI collection, customer communication, and risk management.",
      image: "https://via.placeholder.com/600x300?text=Ongoing+Support", // replace with actual image URL
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };


  const data = {
    labels: ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
    datasets: [
      {
        label: 'Market Value ($ Million)',
        data: [291.9, 315, 345, 375, 405, 430, 450, 463.9], // Sample projected data
        fill: false,
        borderColor: '#F37021',
        tension: 0.1,
      },
    ],
  };

  const features = [
    {
      icon: <MarketAccessIcon />,
      title: "Asset Security",
      description: "Real-time vehicle tracking and asset security using IoT technology",
    },
    {
      icon: <HighInterestIcon />,
      title: "High-Interest Returns",
      description: "Maximize your earnings with our competitive interest rates.",
    },
    {
      icon: <AcquisitionIcon />,
      title: "Seamless Customer Acquisition",
      description: "Acquire customers effortlessly with our advanced marketing strategies.",
    },
    {
      icon: <TechInfrastructureIcon />,
      title: "Complete Tech Infrastructure ",
      description: "Benefit from a robust and reliable tech infrastructure tailored for EV financing.",
    },
    {
      icon: <RecoverySupportIcon />,
      title: "Collection & Recovery Support",
      description: "Get comprehensive support for loan collection and recovery.",
    },
    {
      icon: <NpaMitigationIcon />,
      title: "NPA Mitigation (FLDG)",
      description: "Mitigate NPAs with First Loss Default Guarantee (FLDG) strategies.",
    },
  ];


  const getSquarePosition = (index, total) => {
    const positions = [
      { x: '50%', y: '0%' },     // Top center
      { x: '90%', y: '25%' },    // Right top
      { x: '90%', y: '75%' },    // Right bottom
      { x: '50%', y: '100%' },   // Bottom center
      { x: '10%', y: '75%' },    // Left bottom
      { x: '10%', y: '25%' }     // Left top
    ];
    return positions[index % positions.length];
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Market Value ($ Million)',
        },
      },
    },
  };

  return (
    <div className="nbfc-page" >

      <Box sx={{ flexGrow: 1, padding: 4, backgroundColor: '#010438' }} data-aos="zoom-in-down">
        <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'white' }}>
          Why Partner with FinLe?
        </Typography>
        <br />
        <Grid
          data-aos="zoom-in-down"
          id="nbfcicongrid"
          container
          spacing={4}
          justifyContent="center"
        >
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <div className='why_part_grid'>
                <Paper

                  data-aos="zoom-in-down"
                  elevation={4}
                  sx={{
                    padding: 3,
                    textAlign: 'center',
                    height: '100%',
                    borderRadius: 3,
                    backgroundColor: 'white', // Set a background color for better visibility
                    color: '#010438',
                    alignItems: 'center',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)', // Scale up the box on hover
                      boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.2)', // Box shadow effect
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      fontSize: '4.5rem',
                      color: '#F37021',
                      transition: 'color 0.8s ease',

                    }}
                  >
                    {feature.icon}
                  </IconButton>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontWeight: 'medium',
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography variant="body1">
                    {feature.description}
                  </Typography>
                </Paper>
              </div>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: 3 }}>
            <a href='/support'>
              <button className="cta-button">Contact Us</button>
            </a>
          </Grid>
        </Grid>
      </Box>
     
      {/* Header Section */}
      <header className="header">
        <div className="header-content">
          <div className="text-container" data-aos="zoom-in-down">
            <h1> Fuel Your Growth with EV Financing</h1>
            <p>The electric vehicle (EV) market is rapidly growing, and the e-rickshaw segment is one of its fastest-growing areas. In 2023, the e-rickshaw market revenue reached $291.9 million USD, and it is projected to hit $463.9 million by 2030. This tremendous growth presents an ideal opportunity for NBFCs to tap into a profitable, high-demand market.</p>
            <p> <b>FinLe</b> is here to help NBFCs capitalize on this trend by offering end-to-end support to seamlessly finance EV rickshaws. We bring NBFCs like yours directly into the heart of this emerging market by connecting you with ready-to-finance customers and providing the infrastructure required for financing. Whether you're an NBFC looking to expand your portfolio, enter a trending market, or contribute to reducing the carbon footprint, <b>FinLe </b>is your trusted partner in ensuring success in the EV space.</p>
          </div>
          <div className="image-container" data-aos="zoom-in-down">
            <img src={circle_Img} alt="FinLe Partner" />
          </div>
        </div>

      </header>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' }, // Column for small screens, row for larger screens
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
          // backgroundColor: '#8EC5FC',
            // backgroundImage: 'linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%)',
        }}
      >
        {/* Left Side - Rickshaw Selling Image */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            textAlign: 'center',
            padding: 2,
          }}
        >
          <img
            data-aos="zoom-in-down"
            src={rc_IMG} // Replace with your image path
            alt="Rickshaw for Sale"
            style={{
              width: '80%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </Box>

        {/* Right Side - E-Rickshaw Sales Graph */}
        <Box
          data-aos="zoom-in-down"
          sx={{
            width: { xs: '100%', md: '50%' },
            padding: 2,
          }}
          className="graph-container"
        >
          <Typography variant="h6" sx={{ marginBottom: 2, color: '#333', textAlign: 'center' }}>
            E-Rickshaw Sales Data - 2024
          </Typography>
          <Line data={data} options={options} />
        </Box>
      </Box>





      {/* Main Section */}
      <main className="main-content">

      <div data-aos="zoom-in-down" style={{backgroundColor:"white"}}>
      <center><h1 className='onbording-text'>Onbording Process </h1></center>
      <img style={{marginLeft: "5%"}} src={onBoarding} width="90%" />
     </div>
       

        {/* Call to Action */}
        <section className="cta-section" data-aos="zoom-in-down">
          <h2>Join the EV Revolution with FinLe!</h2>
          <p>The e-rickshaw market is a thriving opportunity for NBFCs looking to expand into new, high-growth segments while supporting the environment and benefiting from high-interest returns. With FinLe by your side, you’ll get access to a complete ecosystem of financing, customer acquisition, technology, and risk management that will drive your business forward.</p>
          <p>Contact us today to learn more about how you can become a part of the e-rickshaw revolution and start financing a greener future!</p>
          <a href='/support' ><button className="cta2-button">Contact Us</button></a>
        </section>
      </main>


    </div>
  );
};

export default NBFCPage;

