/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../styles/ApplyLoan.css';
import mobileImg from '../assets/productImg/mobile_app.png';
import EMICalculator from './EMICalculator';
import FAQ from '../components/FAQ'
import playstoreIcon from '../assets/images/playstoreIcon.svg'
import { Link } from 'react-router-dom';
import app_QR from "../assets/logo/app_QR.png"
import ContactUs from '../components/ContactUs';
const ApplyLoan = () => {
  return (
    <div className='apply-loan'>
      <Container className="apply-loan-container">
        <Row className="apply-loan-section">
          <Col md={4} className="apply-loan-text">

            <h2>Apply for a Loan in 3 Easy Steps</h2>
            <p>Get the funds you need with our easy and hassle-free loan application process.</p>
            <ol>
              <li>Click Get it OR Scan QR & download our mobile app and create an account</li>
              <li>Fill out the loan application form and submit</li>
              <li>
                Get approved and receive your funds
              </li>
            </ol>

            <div className='apply-button' >
            <h3>Download the Finle app to get started</h3>
            <p>
            Download the Finle app, fill out a quick application, and secure your e-rickshaw loan in just a few minutes. Finle, your trusted LSP platform, makes financing fast and hassle-free.
            </p>
            
              <div className='storeBtn'>
                <Link to='https://play.google.com/store/apps/details?id=com.dkglabs.e_savari'>
                  <div className='storeIcon'>
                    <img src={playstoreIcon} alt='playstore' />
                    <Button variant="light" href="https://play.google.com/store/apps/details?id=com.dkglabs.e_savari" >  <span>Get it on</span><br />
                      Google Play
                    </Button>
                    
                    <img src={app_QR} alt='app_QR' className='app_QR' />
                    </div>
                </Link>
              </div>
            </div>
          </Col>
          <Col md={6} className="apply-loan-image" >
            <img src={mobileImg} alt="Mobile App" className="mobile-img" />
             <br/>
            <img src={app_QR} alt="Mobile App" width="30%" className='qr_IMG'/>

          </Col>
        </Row>
        <Row className="loan-calculator-section mt-5">
          <Col>
            {/* Add loan calculator component here */}
            <EMICalculator />
          </Col>
        </Row>
        <Row>
         <Col className='contact-form-parent'>
         <ContactUs/>
         
         </Col>
        </Row>

        <Row className="faqs-section mt-5">
          <Col>
            <FAQ />   {/* Add FAQs component here */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ApplyLoan;
