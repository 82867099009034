/* ----------------------------------------------------------------------------
Information Contained Herein is Proprietary and Confidential. copyright notice in all copies, 
acknowledging that the code is provided without warranties, and strictly prohibiting unauthorized 
sharing or distribution without prior written consent from the copyright holder<DKG Labs Pvt. Ltd>
------------------------------------------------------------------------------ */

import React, { useEffect, useState } from 'react';
import { Form, Button, Alert, Modal, Spinner } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 700,
      delay: 300,
    });
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    phone: '',
    message: '',
  });

  const [phoneError, setPhoneError] = useState(''); // State for phone number validation
  const [submissionError, setSubmissionError] = useState(''); // State for submission errors
  const [submissionSuccess, setSubmissionSuccess] = useState(''); // State for submission success
  const [loading, setLoading] = useState(false); // State for loading
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the input is for phone, sanitize and limit to 10 digits
    if (name === 'phone') {
      const sanitizedValue = value.replace(/\D/g, '');
      const limitedValue = sanitizedValue.slice(0, 10);
      setFormData((prevFormData) => ({ ...prevFormData, [name]: limitedValue }));

      // Real-time validation
      if (limitedValue.length !== 10) {
        setPhoneError('Mobile number must be exactly 10 digits.');
      } else {
        setPhoneError('');
      }
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous messages
    setPhoneError('');
    setSubmissionError('');
    setSubmissionSuccess('');

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      setPhoneError('Mobile number must be exactly 10 digits.');
      return;
    }

    // Proceed with form submission
    const apiUrl = 'https://finle-api-gateway.azurewebsites.net/user-service/addContactUs';

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();

    const requestBody = {
      txnDate: formattedDate,
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      category: formData.subject,
      remarks: formData.message,
    };

    const token = localStorage.getItem('token');
    setLoading(true); // Show loader

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.responseStatus.statusCode === 0) {
          setSubmissionSuccess('Form submitted successfully!');
          setShowModal(true); // Show success modal
          // Reset form fields
          setFormData({
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
          });
        } else {
          setSubmissionError('Form submission failed. Please try again.');
          setShowModal(true); // Show error modal
        }
      } else {
        setSubmissionError('Form submission failed. Please try again.');
        setShowModal(true); // Show error modal
        console.error(response.statusText);
      }
    } catch (error) {
      console.error(error);
      setSubmissionError('An error occurred. Please try again.');
      setShowModal(true); // Show error modal
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSubmissionError('');
    setSubmissionSuccess('');
  };

  return (
    <div className='ContactUs'>
      {/*<h1 data-aos="zoom-in-down">NEWSLETTER</h1>
      <p className='testimonal_subheading' style={{ color: "black" }} data-aos="zoom-in-down">
        Get the latest news about Fintech! We promise no spam, only bundles of joy.
      </p>
      <InputGroup className="mb-3 contact-email" size="lg" data-aos="zoom-in-down">
        <Form.Control
          placeholder="Enter your email address"
          aria-label="Enter your email address"
          aria-describedby="basic-addon2"
          className='input-place'
        />
        <Button variant="primary" id="button-addon2">
          Subscribe
        </Button>
      </InputGroup>

      */}

      <div className='Contact-form'>
        <div>
          <h1>Don't hesitate to <br /> contact us for any <br /> information</h1>
          <p style={{ width: "190px" }}>Call us for immediate support to this number</p>
          +91 8827040150
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 formGroup" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3 formGroup" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
            <Form.Text style={{ color: "white" }}>
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className='formGroup' controlId="formBasicPhone">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              required
              placeholder="Enter Mobile Number"
              value={formData.phone}
              onChange={handleChange}
              maxLength={10} // Limit input to 10 digits
              pattern="\d{10}" // Ensure exactly 10 digits
              isInvalid={!!phoneError} // Highlight input if there's an error
            />
            <Form.Control.Feedback type="invalid">
              {phoneError}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='formGroup' controlId="formSubject">
            <Form.Label>How can we assist you?</Form.Label>
            <Form.Select
              name="subject"
              required
              value={formData.subject}
              onChange={handleChange}
            >
              <option value="">Select an option</option>
              <option value="General Inquiry">General Inquiry</option>
              <option value="Loan Application">Loan Application</option>
              <option value="Dealer Partnership">Dealer Partnership</option>
              <option value="Technical Support">Technical Support</option>
              <option value="NBFC Partnership">NBFC Partnership</option>
              <option value="Others">Others</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className='formGroup' controlId="formBasicMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="message"
              required
              placeholder="Enter your inquiry or message."
              value={formData.message}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="primary" className='formGroup button-addon2' type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner animation="border" size="sm" />
                {' '}Sending...
              </>
            ) : (
              'Send Message'
            )}
          </Button>
        </Form>
      </div>

      {/* Modal for success/error messages */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{submissionSuccess ? 'Success' : 'Error'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submissionSuccess ? submissionSuccess : submissionError}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContactUs;
