import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Table } from "react-bootstrap";
import { Doughnut, Line } from "react-chartjs-2";
import "../styles/EMICalculator.css";
import Chart from "chart.js/auto";

const LoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(150000);
  const [interestRate, setInterestRate] = useState(10.0); // Allow decimals in interest rate
  const [loanLength, setLoanLength] = useState(18);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [yearlyInterest, setYearlyInterest] = useState([]);
  const [yearlyPrincipal, setYearlyPrincipal] = useState([]);
  const [years, setYears] = useState([]);
  const [pieChartValues, setPieChartValues] = useState({
    principal: loanAmount,
    interest: 0,
  });
  const [isYears, setIsYears] = useState(false); // State to toggle between months and years
  const [emiSchedule, setEmiSchedule] = useState([]);

  function calculateLoanDetails(loan, rate, emi, tenureInMonths) {
    let totalInterest = 0;
    let yearlyInterest = [];
    let yearlyPrincipal = [];
    let years = [];
    let counter = 0;
    let principal = 0;
    let interests = 0;
    let loanAmount = loan;
    let schedule = []; // Store the EMI details for each month

    for (let month = 1; month <= tenureInMonths; month++) {
      let interest = Math.round(loanAmount * rate);
      let principalPayment = emi - interest;
      loanAmount = Math.max(0, loanAmount - principalPayment); // Ensure balance doesn't go negative
      totalInterest += interest;
      principal += principalPayment;
      interests += interest;

      // Add EMI details to the schedule array
      schedule.push({
        month,
        principal: principalPayment,
        interest,
        balance: loanAmount,
      });

      if (++counter === 12 || month === tenureInMonths) {
        years.push(years.length + 1);
        yearlyInterest.push(Math.round(interests));
        yearlyPrincipal.push(Math.round(principal));
        counter = 0;
        principal = 0;
        interests = 0;
      }
    }

    setYearlyPrincipal(yearlyPrincipal);
    setYearlyInterest(yearlyInterest);
    setYears(years);
    setEmiSchedule(schedule); // Update EMI schedule

    return totalInterest;
  }

  useEffect(() => {
    const r = interestRate === 0 ? 0 : interestRate / 1200; // Monthly interest rate
    const n = isYears ? loanLength * 12 : loanLength; // Total number of payments (in months or years)

    const calculatedEMI = interestRate === 0
      ? Math.round(loanAmount / n)
      : Math.round((loanAmount * r * (1 + r) ** n) / ((1 + r) ** n - 1));
    setMonthlyPayment(calculatedEMI);

    const calculatedTotalInterest = Math.round(
      interestRate === 0 ? 0 : calculateLoanDetails(loanAmount, r, calculatedEMI, n)
    );
    setTotalInterest(calculatedTotalInterest);

    setPieChartValues({
      principal: loanAmount,
      interest: calculatedTotalInterest,
    });
  }, [loanAmount, interestRate, loanLength, isYears]);

  const handleLoanAmountChange = (event) => {
    const value = Math.round(event.target.value);
    setLoanAmount(value > 0 ? value : 0);
  };

  const handleInterestRateChange = (event) => {
    const value = parseFloat(event.target.value); // Allow decimal input
    setInterestRate(value > 0 ? value : 0);
  };

  const handleLoanLengthChange = (event) => {
    const value = Math.round(event.target.value);
    setLoanLength(value > 0 ? value : 0);
  };

  const handleTenureToggle = () => {
    setIsYears(!isYears); // Toggle between months and years
    setLoanLength(isYears ? loanLength * 12 : Math.round(loanLength / 12)); // Adjust loan length accordingly
  };

  const handleBlur = () => {
    // Reset loan amount if empty or zero
    if (!loanAmount || loanAmount < 5000) setLoanAmount(5000);
    // Reset loan length if empty or below minimum
    if (!loanLength || loanLength < 2) setLoanLength(2);
  };

  const lineChartData = {
    labels: years,
    datasets: [
      {
        type: "line",
        label: "Yearly Principal Paid",
        borderColor: "rgb(54, 162, 235)",
        data: yearlyPrincipal,
      },
      {
        type: "line",
        label: "Yearly Interest Paid",
        borderColor: "rgb(255, 99, 132)",
        data: yearlyInterest,
      },
    ],
  };

  const pieChartData = {
    labels: ["Principal", "Interest"],
    datasets: [
      {
        label: "Loan Amount",
        data: [pieChartValues.principal, pieChartValues.interest],
        backgroundColor: ["#010438", "#F37021"],
      },
    ],
  };

  const lineChartOptions = {
    scales: {
      yAxes: [{ ticks: { beginAtZero: true } }],
    },
  };

  return (
    <Container className="emi-container">
      <div className="emi-header">
        <h1>Loan Calculator</h1>
      </div>
      <Row>
        <Col md={8}>
          <Form className="emi-body">
            <Form.Group controlId="loanAmountRange">
              <Form.Label>
                Amount <span style={{ color: "#6258A8" }}>₹{loanAmount}</span>
                <Form.Control
                  type="number"
                  min="0"
                  max="500000"
                  value={loanAmount}
                  onChange={handleLoanAmountChange}
                  onBlur={handleBlur}
                />
              </Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="500000"
                step="5000"
                onChange={handleLoanAmountChange}
              />
            </Form.Group>
            <Form.Group controlId="loanLengthRange">
              <Form.Label>
                Loan Tenure <span style={{ color: "#6258A8" }}>{loanLength} {isYears ? "Years" : "Months"}</span>
              </Form.Label>
              <div className="tenure-toggle">
                <Form.Control
                  type="number"
                  min="2"
                  max={isYears ? 48 : 576} // Max of 48 years or 576 months
                  value={loanLength}
                  onChange={handleLoanLengthChange}
                  onBlur={handleBlur}
                  className="tenure_input"
                />
                <div className="tenure_toggle_parent">
                  <span
                    className={`tenure-option ${isYears ? "selected" : ""}`}
                    onClick={() => setIsYears(true)}
                  >
                    Years
                  </span>
                  <span
                    className={`tenure-option ${!isYears ? "selected" : ""}`}
                    onClick={() => setIsYears(false)}
                  >
                    Months
                  </span>
                </div>
              </div>
              <Form.Control
                type="range"
                min="2"
                max={isYears ? 48 : 576}
                value={loanLength}
                step="1"
                onChange={handleLoanLengthChange}
              />
            </Form.Group>
            <Form.Group controlId="interestRateRange">
              <Form.Label>
                % Interest <span style={{ color: "#6258A8" }}>{interestRate}%</span>
              </Form.Label>
              <Form.Control
                type="number"
                min="0"
                max="40"
                step="0.01" // Allow decimal input
                value={interestRate}
                onChange={handleInterestRateChange}
              />
              <Form.Control
                type="range"
                min="0"
                max="40"
                step="0.1"
                onChange={handleInterestRateChange}
              />
            </Form.Group>
          </Form>
        </Col>
        <Col md={4}>
        <Doughnut data={pieChartData} />
      </Col>
      </Row>
      
      <Row>
        <Col md={6} className="summary_table">
          <h3>Loan Summary</h3>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Monthly Payment EMI</td>
                <td>₹{monthlyPayment}</td>
              </tr>
              <tr>
                <td>Principal</td>
                <td>₹{loanAmount}</td>
              </tr>
              <tr>
                <td>Total Interest</td>
                <td>₹{totalInterest}</td>
              </tr>
              <tr>
                <td>Total Payable</td>
                <td>₹{loanAmount + totalInterest}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
       <br/>
      <Row className="schedule_Table_head">
        <Col md={12} >
          <h3 style={{paddingLeft:"5%"}}>EMI Schedule</h3>
          <Table striped bordered hover style={{ border: "2px solid #010438" }}>
            <thead style={{ background: "#010438", color: "white" }}>
              <tr>
                <th>Month</th>
                <th>EMI Amount</th>
                <th>Principal</th>
                <th>Interest</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {emiSchedule.map((schedule, index) => (
                <tr key={index}>
                  <td>{schedule.month}</td>
                  <td>{schedule.principal + schedule.interest}</td>
                  <td>{schedule.principal}</td>
                  <td>{schedule.interest}</td>
                  <td>{schedule.balance}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <br/>
      <br/>
    </Container>
  );
};

export default LoanCalculator;
